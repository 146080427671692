<template>
    <div class="loaderMain">
        <div class="loader-wrapper">
            <div class="midtown_icon">
                <span>
                    <img src="@/assets/images/bai-com-colored.svg" alt="logo">
                </span>
                <img class="bg-logo" src="@/assets/images/bai-com-white.svg" alt="logo">
            </div>
        </div>
    </div>
</template>