import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-block confirm-details" }
const _hoisted_2 = { class: "label-block mb-0" }
const _hoisted_3 = { class: "d-flex justify-content-between w-100 switch-container" }
const _hoisted_4 = { class: "label-block_label" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "switch-toggle" }
const _hoisted_7 = ["id"]
const _hoisted_8 = ["for"]
const _hoisted_9 = ["data-checked", "data-unchecked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, [
            _createTextVNode(_toDisplayString(_ctx.label), 1),
            (_ctx.isRequired)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "*"))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isDeclaration && !_ctx.selectedValue && _ctx.members.length > 1)
            ? (_openBlock(), _createBlock(_component_el_select, {
                key: 0,
                onChange: _ctx.selectedMember,
                "model-value": _ctx.member,
                filterable: "",
                multiple: "",
                "collapse-tags": "",
                "collapse-tags-tooltip": ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      class: "vehicle",
                      key: index,
                      label: item.label,
                      value: item.value
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["onChange", "model-value"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            id: _ctx.id,
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedValue = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeValue && _ctx.changeValue(...args)))
          }, null, 40, _hoisted_7), [
            [_vModelCheckbox, _ctx.selectedValue]
          ]),
          _createElementVNode("label", { for: _ctx.id }, [
            _createElementVNode("div", {
              class: "switch-toggle__switch",
              "data-checked": _ctx.$t('no'),
              "data-unchecked": _ctx.$t('yes')
            }, null, 8, _hoisted_9)
          ], 8, _hoisted_8)
        ])
      ])
    ])
  ]))
}